import { Controller } from "@hotwired/stimulus";

import mapboxgl from "mapbox-gl";

export default class extends Controller {
  static targets = ["map"];
  static values = {
    lat: Number,
    long: Number,
    publicKey: String,
    locationsUrl: String,
    positions: Object,
  };

  initialize() {
    mapboxgl.accessToken = this.publicKeyValue;
  }

  connect() {
    this.hoveredStateId = null;

    this.map = new mapboxgl.Map({
      container: this.mapTarget,
      style: "mapbox://styles/mapbox/satellite-streets-v11",
      center: [this.longValue, this.latValue], // starting position
      zoom: 4, // starting zoom
    });

    this.map.addControl(new mapboxgl.NavigationControl());
    this.map.addControl(new mapboxgl.GeolocateControl());

    this.map.on("load", () => {
      this.onMapLoaded();
    });

    this.map.on("moveend", (e) => {
      console.log("event type:", e.type);
      console.log(this.map.getZoom());
      console.log(this.map.getBounds().toArray());
      this.loadLocations();
    });
  }

  disconnect() {
    this.map.remove();
    this.map = undefined;
  }

  // Do something when the map has loaded.
  onMapLoaded() {
    let controller = this;
    this.map.addSource("locations", {
      type: "geojson",
      // Use a URL for the value for the `data` property.
      data: `${this.locationsUrlValue}?bounds=${this.map
        .getBounds()
        .toArray()}`,
    });

    if (this.positionsValue) {
      console.log(this.positionsValue);
      this.map.addSource("positions", {
        type: "geojson",
        data: this.positionsValue,
      });

      this.map.addLayer({
        id: "positions-icons",
        type: "circle",
        source: "positions",
        paint: {
          // Make circles larger as the user zooms from z12 to z22.
          "circle-radius": {
            base: 6.75,
            stops: [[8, 4], [11, 10], [16, 40]],
          },
          // Color circles by ethnicity, using a `match` expression.
          "circle-color": [
            "match",
            ["get", "anchor"],
            "start",
            "#008450",
            "end",
            "#b81d13",
            /* other */ "#ccc",
          ],
          "circle-stroke-width": 2,
          "circle-stroke-color": "white"
        },
      });

      this.map.on("click", "positions-icons", (e) => {
        console.log("click...");
        // Copy coordinates array.
        const coordinates = e.features[0].geometry.coordinates.slice();
        const description = `${e.features[0].properties.name}`;
        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }
  
        new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setHTML(description)
          .addTo(this.map);
      });
  
      // Change the cursor to a pointer when the mouse is over the locations layer.
      this.map.on("mouseenter", "positions-icons", () => {
        console.log("mouseenter");
        this.map.getCanvas().style.cursor = "pointer";
      });
  
      // Change it back to a pointer when it leaves.
      this.map.on("mouseleave", "positions-icons", () => {
        console.log("mouseleave");
        this.map.getCanvas().style.cursor = "";
      });
    }

    this.map.addLayer({
      id: "locations-layer",
      type: "circle",
      source: "locations",
      paint: {
        "circle-radius": 4,
        "circle-stroke-width": 2,
        "circle-color": "#efb700",
        "circle-stroke-color": "white",
      },
    });

    this.map.on("click", "locations-layer", (e) => {
      console.log("click...");
      // Copy coordinates array.
      const coordinates = e.features[0].geometry.coordinates.slice();
      const description = `${e.features[0].properties.profile_name}<br/>${e.features[0].properties.date}`;
      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      new mapboxgl.Popup()
        .setLngLat(coordinates)
        .setHTML(description)
        .addTo(this.map);
    });

    // Change the cursor to a pointer when the mouse is over the locations layer.
    this.map.on("mouseenter", "locations-layer", () => {
      console.log("mouseenter");
      this.map.getCanvas().style.cursor = "pointer";
    });

    // Change it back to a pointer when it leaves.
    this.map.on("mouseleave", "locations-layer", () => {
      console.log("mouseleave");
      this.map.getCanvas().style.cursor = "";
    });
  }

  mapZoomChanged() {
    console.log("mapZoomChanged");
    console.log(this.map.getZoom());
    this.loadLocations();
  }

  loadLocations() {
    console.log("loadLocations");
    if (this.map.isSourceLoaded("locations")) {
      fetch(
        `${this.locationsUrlValue}?bounds=${this.map.getBounds().toArray()}`
      ).then((response) => {
        response.json().then((data) => {
          this.map.getSource("locations").setData(data);
        });
      });
    } else {
      this.map.addSource("locations", {
        type: "geojson",
        data: `${this.locationsUrlValue}?bounds=${this.map
          .getBounds()
          .toArray()}`,
      });
    }
  }
}
