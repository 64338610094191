import { Controller } from "@hotwired/stimulus";
import { patch } from "@rails/request.js";
import mapboxgl from "mapbox-gl";

export default class extends Controller {
  static targets = [
    "addressBar",
    "locationButton",
    "submitButton",
    "latitudeField",
    "longitudeField",
    "map",
    "spinner",
  ];

  static values = { backendUrl: String, publicKey: String };

  initialize() {
    mapboxgl.accessToken = this.publicKeyValue;
  }

  connect() {
    console.log(this.backendUrlValue);
    if ("geolocation" in navigator) {
      /* geolocation is available */
      console.log("Geolocation is available");
      let controller = this;
      this.watchId = navigator.geolocation.watchPosition(
        (position) => {
          console.log("watch position on load");
          console.log(position);
          controller.postLocation(position);
        },
        (err) => {
          console.warn(`ERROR(${err.code}): ${err.message}`);
          controller.postLocationBlocked();
        }
      );
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log("get position on load");
          console.log(position);
          controller.postLocation(position);
          // controller.locationButtonTarget.disabled = false;
          // controller.submitButtonTarget.disabled = false;
          // controller.latitudeFieldTarget.value = position.coords.latitude;
          // controller.longitudeFieldTarget.value = position.coords.longitude;
          // controller.dispatch("retreivedLocation", {
          //   detail: {
          //     lat: position.coords.latitude,
          //     lng: position.coords.longitude,
          //   },
          // });
        },
        (err) => {
          console.warn(`ERROR(${err.code}): ${err.message}`);
          controller.postLocationBlocked();
        }
      );
    } else {
      /* geolocation IS NOT available */
      console.log("No Geolocation!");
      controller.postLocationUnavailable();
    }
    this.map = new mapboxgl.Map({
      container: this.mapTarget,
      style: "mapbox://styles/mapbox/satellite-streets-v11",
      center: [0, 0], // starting position
      zoom: 1, // starting zoom
    });
  }

  loadLocation(event) {
    console.log("load location");
    event.preventDefault();
    this.locationButtonTarget.disabled = true;
    let controller = this;
    this.spinnerTarget.classList.remove("hidden");
    this.watchId2 = navigator.geolocation.watchPosition(
      (position) => {
        console.log("watch position on click");
        console.log(position);
        controller.postLocation(position);
      },
      (err) => {
        console.warn(`ERROR(${err.code}): ${err.message}`);
        controller.postLocationBlocked();
      }
    );
    navigator.geolocation.getCurrentPosition(
      (position) => {
        console.log("get position on click");
        console.log(position);
        controller.postLocation(position);
      },
      (err) => {
        console.warn(`ERROR(${err.code}): ${err.message}`);
        controller.postLocationBlocked();
      }
    );
    return false;
  }

  addressFilled() {
    console.log("address filled");
    this.submitButtonTarget.disabled = false;
  }

  postLocation(position) {
    if (this.marker) {
      this.marker.setLngLat([
        position.coords.longitude,
        position.coords.latitude,
      ]);
    } else {
      this.marker = new mapboxgl.Marker()
        .setLngLat([position.coords.longitude, position.coords.latitude])
        .addTo(this.map);
    }
    this.spinnerTarget.classList.add("hidden");
    this.map.flyTo({
      center: [position.coords.longitude, position.coords.latitude],
      zoom: 15,
      duration: 10000,
      essential: false,
    });
    let positionBody = {
      status: "location_update",
      coords: {
        accuracy: position.coords.accuracy,
        altitude: position.coords.altitude,
        altitudeAccuracy: position.coords.altitudeAccuracy,
        heading: position.coords.heading,
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        speed: position.coords.speed,
      },
      timestamp: position.timestamp,
      user_agent: window.navigator.userAgent,
    };
    console.log(position.coords);
    console.log(positionBody);
    const response = patch(this.backendUrlValue, {
      body: positionBody,
    });
    if (response.ok) {
      console.log(response);
    }
  }

  postLocationBlocked() {
    let positionBody = {
      status: "location_blocked",
    };
    this.spinnerTarget.classList.add("hidden");
    const response = patch(this.backendUrlValue, {
      body: positionBody,
    });
    if (response.ok) {
      console.log(response);
    }
  }

  postLocationUnavailable() {
    let positionBody = {
      status: "location_unavailable",
    };
    this.spinnerTarget.classList.add("hidden");
    const response = patch(this.backendUrlValue, {
      body: positionBody,
    });
    if (response.ok) {
      console.log(response);
    }
  }
}
