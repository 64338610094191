import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  static values = { url: String };
  static targets = ["searchField"];

  connect() {
    console.log("search");
  }

  search(event) {
    console.log("searching...");
    let query = `?`
    this.searchFieldTargets.forEach( (searchField) =>  {
        console.log(searchField.name);
        console.log(searchField.value);
        query = `${query}&${searchField.name}=${searchField.value}`
    });
    console.log(query);
    Turbo.visit(query);
    // get(`${this.urlValue}?${event.target.name}=${event.target.value}`, {
    //   responseKind: "turbo-stream",
    // });
  }
}
